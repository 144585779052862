.centered {
  height: 70px;
  width: 100%;
  position: relative;
}
.blob-1,
.blob-2 {
  width: 70px;
  height: 70px;
  position: absolute;
  background: #fff;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.blob-1 {
  left: 20%;
  animation: osc-l 2.5s ease infinite;
}
.blob-2 {
  left: 80%;
  animation: osc-r 2.5s ease infinite;
  background: linear-gradient(
    to right bottom,
    rgb(249, 154, 45),
    rgb(246, 92, 139) 71%
  );
}
@keyframes osc-l {
  0% {
    left: 20%;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 20%;
  }
}
@keyframes osc-r {
  0% {
    left: 80%;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 80%;
  }
}
