.toast-message {
    background: rgb(213, 213, 215);
    color: rgb(23, 6, 6);
    font-size: 20px;
    /* width: 34vw; */
    padding: 30px 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 120%;
}