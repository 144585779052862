.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal {
  z-index: 100;
  background: white;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 3px;
  max-width: 800px;
  padding: 2rem;
  color: black;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: 0.3;
  cursor: pointer;
  border: none;
}

.button {
  font-size: 0.9rem;
  font-weight: 700;
  border: none;
  border-radius: 3px;
  padding: 0.3rem 1rem;
  margin-left: 0.5rem;
}

/* responsive part */
@media (max-width: 768px) {
  .modal {
    margin: 1.75rem auto;
    max-width: 650px;
  }
}

@media (max-width: 600px) {
  .modal {
    margin: 1.75rem auto;
    max-width: 550px;
  }
}

@media (max-width: 480px) {
  .modal {
    margin: 1.75rem auto;
    max-width: 420px;
  }
}