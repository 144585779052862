.modalBackground {
  
  background-color: #2c2c2e;
  position:absolute;
  justify-content: center;
  align-items: center;
  margin-top: -60%;
  margin-left: 24%;
  border-radius: 12px;
}
.modalContainer {

  border-radius: 12px;
  background-color: #3a3a3c;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  flex-direction: column;
  padding: 25px;
}
.modalContainer .title {
  display: inline-block;
  text-align: center;
  /* margin-top: 10px; */
  color: rgb(239, 237, 237);
  font-size: 12px;
  font-weight: 100;
  margin-top: 10px;
}
.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  margin-top: -15px;
  color: red;
}
.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.modalContainer .footer button {
  width: 120px;
  height: 40px;
  margin: 10px;
  border: none;
  /* background-color: cornflowerblue; */
  /* background: linear-gradient(29deg,rgb(249,154,45),rgb(246,92,139) 71%); */
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

#cancelBtn {
  background-color: #2c2c2e;
  /* background: linear-gradient(29deg,rgb(249,154,45),rgb(246,92,139) 71%); */
}
.yesBtn{
  background: linear-gradient(29deg,rgb(249,154,45),rgb(246,92,139) 71%);
}